






import Vue from 'vue';
import vtable from '@/components/table.vue';
import { RootState } from '@/store';

export default Vue.extend({
	name: 'MarkList',
	components: {
		vtable
	},
	computed: {
		markings() {
			return {
				headers: [
					{ text: 'file', value: 'file' },
					{ text: 'code', value: 'code' },
					...(this.$store
						.state as RootState).projectCurrent.fileCodes.headers.filter(
						(v: { value: string }) =>
							v.value === 'seltext' || v.value === 'date'
					)
				],
				data: (this.$store.state as RootState).projectCurrent.fileCodes.data
					.filter((v: { status: number }) => v.status === 1)
					.map((v: { fid: number; cid: number }) => ({
						...v,
						file: this.$store.getters.getFileNameById(v.fid),
						code: this.$store.getters.getCodeNameById(v.cid)
					}))
			};
		}
	}
});
